<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        :button-actions-on-left="true"
        @runSearch="runSearch"
        :search-tips="$t('member.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import {
  BAvatar
} from 'bootstrap-vue'
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";
import i18n from "@/libs/i18n";

const measureItemWidth = '170px';

export default {
  components: {
    BAvatar,
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('kioskMemberHealthData'),
      view_permission: common.checkPermission('Kiosk_Menu_Health_Data'),
      // view_permission: true,

      showDetailCols: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },

      status_map: [], // 會員狀態
      bg_period_map: [], // 血糖類型
      dietary_map: [], // 飲食狀況
      conscious_map: [], // 意識
      emotional_map: [], // 情緒表現

      table_actions: [
        {
          text: common.getI18n('common.download_report'),
          variant: 'primary',
          fun: 'jumpToReport',
          permission: 'Kiosk_Menu_Health_Data'
        }
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: false,
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
        {
          field: 'statusList',
          label: 'member.status',
          type: 'select',
          multiple: true,
          options: []
        }
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID'),
        roomID: common.getCustomConfigByKey('roomID'),
      }
    }
  },

  computed: {
    header_actions() {
      return [
        // {
        //   text: '展示更多',
        //   variant: this.showDetailCols ? 'primary' : 'secondary',
        //   fun: () => this.showDetailCols = !this.showDetailCols,
        //   permission: 'Kiosk_Menu_Health_List',
        //   pill: true,
        // },
      ]
    },
    columns() {
      const origin = [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'member.chineseName',
          field: 'chineseName',
          changeHTML: function (value, row) {
            let avatar = ''
            if (row.photo) {
              avatar = common.getServerUrl() + row.photo
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          },
          width: '180px'
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          isDetailCol: true,
          width: '110px',
        },
        // {
        //   label: 'member.sex',
        //   field: 'isMale',
        //   change: (isMale) => isMale ? common.getI18n('member.sex_male') : common.getI18n('member.sex_female'),
        //   width: '70px',
        // },
        {
          label: 'member.status',
          field: 'status',
          isDetailCol: true,
          change: this.changeStatus,
          width: '80px',
        },
        {
          label: this.$t('kiosk.temperature'),
          field: 'bT_Measured_DateTime',
          changeHTML: (value, row) => (row.bt ? `<span>${row.bt}℃</span>` : '') +
            (row.bT_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.bT_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('kiosk.blood_pressure'),
          field: 'bP_Measured_DateTime',
          changeHTML: (value, row) => (row.bP_Systolic ? `<span>${row.bP_Systolic}/${row.bP_Diastolic}mmHg</span>` : '') +
            (row.bP_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.bP_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('kiosk.pulse'),
          field: 'bP_Measured_DateTime',
          changeHTML: (value, row) => (row.bP_Pulse ? `<span>${row.bP_Pulse}bpm</span>` : '') +
            (row.bP_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.bP_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('kiosk.oximetry'),
          field: 'spO2_Measured_DateTime',
          changeHTML: (value, row) => (row.spO2 ? `<span>${row.spO2}%</span>` : '') +
            (row.spO2_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.spO2_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('kiosk.weight'),
          field: 'weight_Measured_DateTime',
          changeHTML: (value, row) => (row.weight ? `<span>${row.weight}kg</span>` : '') +
            (row.weight_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.weight_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('kiosk.height'),
          field: 'height_Measured_DateTime',
          changeHTML: (value, row) => (row.height ? `<span>${row.height}cm</span>` : '') +
            (row.height_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.height_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('kiosk.blood_glucose'),
          field: 'bG_Measured_DateTime',
          changeHTML: (value, row) => {
            const line1 = (row.bG_Glucose
              ? (row.bG_Period
              ? `<span class="infoSub">${this.bg_period_map?.find(e => e.statusCode === row.bG_Period)?.statusDesc ?? row.bG_Period}</span> `
              : '') + `<span>${row.bG_Glucose}mmol/L</span>`
              : '')
            const line2 = (row.bG_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.bG_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : '')
            return line1 + line2
          },
          width: '180px',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('kiosk.grip_strength'),
          field: 'handgrip_Measured_DateTime',
          changeHTML: (value, row) => (row.handgrip ? `<span>${row.handgrip}mbar</span>` : '') +
            (row.handgrip_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.handgrip_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('呼吸'),
          field: 'breaths_Measured_DateTime',
          changeHTML: (value, row) => (row.breaths ? `<span>${row.breaths}bpm</span>` : '') +
            (row.breaths_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.breaths_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : ''),
          width: measureItemWidth,
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('飲食狀況'),
          field: 'dietary_Measured_DateTime',
          changeHTML: (value, row) => {
            const line1 = (row.dietary
              ? `<span>${this.dietary_map?.find(e => e.statusCode === row.dietary)?.statusDesc ?? row.dietary}</span>`
              : '')
            const line2 = (row.dietary_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.dietary_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : '')
            return line1 + line2
          },
          width: '170px',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('意識'),
          field: 'conscious_Measured_DateTime',
          changeHTML: (value, row) => {
            const line1 = (row.conscious
              ? `<span>${this.conscious_map?.find(e => e.statusCode === row.conscious)?.statusDesc ?? row.conscious}</span>`
              : '')
            const line2 = (row.conscious_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.conscious_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : '')
            return line1 + line2
          },
          width: '170px',
          type: 'number',
          sortable: false,
        },
        {
          label: this.$t('情緒表現'),
          field: 'emotional_Measured_DateTime',
          changeHTML: (value, row) => {
            const line1 = (row.emotional
              ? `<span>${this.emotional_map?.find(e => e.statusCode === row.emotional)?.statusDesc ?? row.emotional}</span>`
              : '')
            const line2 = (row.emotional_Measured_DateTime
              ? `<br/><span class="measuredTime">${this.$moment(row.emotional_Measured_DateTime)
                .format('YYYY-MM-DD HH:mm:ss')}</span>`
              : '')
            return line1 + line2
          },
          width: '170px',
          type: 'number',
          sortable: false,
        },
        {
          label: 'common.action',
          field: 'action',
          width: '130px',
          sortable: false,
        },
      ]
      return this.showDetailCols ? origin : origin.filter(col => col.isDetailCol !== true)
    },
  },

  mounted() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    }
    common.getSearchRoomOptions('roomID', this.search_default.floorID, this)
    this.getList()

    common.getSearchMemberStatusOptions('statusList', this)
      .then(res => this.status_map = res)

    this.$requestWehealth({
      method: 'get',
      url: '/kiosk/GetMeasureDropDownList',
    }).then(({ data }) => {
      this.bg_period_map = data?.bgPeriod ?? []
      this.dietary_map = data?.dietary ?? []
      this.conscious_map = data?.conscious ?? []
      this.emotional_map = data?.emotional ?? []
    })
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      // const api = '/member/GetMemberList'
      const api = '/member/GetMemberMeasureLastList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.members
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    jumpToReport: function (data) {
      this.$router.push({
        name: 'kioskMemberHealthDataReport',
        params: { memberId: common.encrypt(data.memberID) },
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
  }
}
</script>

<style scoped>
::v-deep .measuredTime {
  font-size: smaller;
  opacity: 0.7;
}
</style>
